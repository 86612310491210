<template>
  <div>
    <FormEtest />
  </div>
</template>

<script>
export default {
  components: {
    FormEtest: () => import("./components/FormEtest.vue"),
  },
};
</script>
